/*
 * @Date: 2023-03-13 17:16:39
 * @LastEditors: simLarry
 * @LastEditTime: 2023-06-11 10:57:56
 * @FilePath: \F1-M1-QRP-CODE\config\common\reg.js
 */
// 用户名格式正则

export const RegName = /^[a-zA-Z0-9]{6,16}$/;

export const RegNameNumber =/^\d+$/;

export const RegNameLetter = /^[a-zA-Z]+$/;

// 密码格式正则
export const RegPassword = /(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=[\^#$@]*)([a-zA-Z0-9]([\^#$@]*))+$/;
// 电话号码格式正则
export const RegPhone = /^[1-9]{1}[0-9]{10}$/;
// 电子邮箱格式正则
export const RegEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;