/*
 * @Date: 2023-03-13 17:18:22
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-17 16:10:58
 * @FilePath: \F1-M1-QRP-CODE\components\modal\index.js
 */
import React from "react";

class Modal extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }


    close(){
        this.props.closeModal();
    }
    render(){
        let { isModal , error_text } = this.props;
        return (
            
            // 模态窗
            <>
                <div className="modal" style={{display:isModal ? 'block' : 'none'}}>
                    <div className="modal-box">
                        <div className="header">登录失败</div>
                        <div className="center">{error_text}</div>
                        <div className="btn" onClick={()=>{this.close()}}>ok</div>
                    </div>
                </div>

                
            </>

        )
    }

}

export default Modal;