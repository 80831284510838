/*
 * @Date: 2023-02-12 13:09:32
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-17 12:33:00
 * @FilePath: \F1-M1-QRP-CODE\config\Fatch.config.js
 */
import Error from './Fatch-Error.js'
import HostConfig from '$CONFIG/Host.config'
import Router from 'next/router';

// 登录|超时 清除掉登录信息

function RemoveItemStorage(needToLogin){
	localStorage.removeItem('memberToken');
	localStorage.removeItem('memberInfo');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('username');
	sessionStorage.removeItem('loginStatus');
}

/**
 * @param { Function } fetch_promise
 * @param { number | any } timeout { 超时时间 }
 */
export function timeout_fetch(fetch_promise, timeout = 120000) {
	let timeout_fn = null;
	let timeout_promise = new Promise(function (resolve, reject) {
		timeout_fn = function () {
			reject('请求超时!!!');
		};
	});
	let abortable_promise = Promise.race([fetch_promise, timeout_promise]);
	setTimeout(function () {
		timeout_fn();
	}, timeout);

	return abortable_promise;
}


/**
 * @param { string } url
 * @param { string } method
 * @param { JSON } params
 * @param { Boolean } removeToken
 * @returns
 * 
 */
export const fetchRequest = (url , method , params = '' , Apiversion = '', removeToken = false)=>{
	let header;
	if(!url.includes('cms')){
		header = {
			'Content-Type': 'application/json; charset=utf-8',
			'Culture': 'zh-cn'
		};
	}
	return new Promise(function(resolve , reject){
		timeout_fetch(fetch(url + Apiversion , {
			method : method,
			headers : header,
			body: !params ? null : JSON.stringify(params)
			
		})).then((response) => response.json())
		   .then((data) =>{
			console.log(data)
			if(data.error_details){
				if(data.error_details.Code === "GEN0002"){
					Router.push('/restrict');
					return;
				}
			}
			   resolve(data);
		   })
	})
}


