import React from "react";
import { checkAffQueryString } from "../../actions/util";
import { AFFILIATE_QUERYSTRING_KEY, updateQueryStringParameter } from "../../helpers/";

export default class Logo extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        affValue: '',
      };
    }

    componentDidMount(){
      let aff = checkAffQueryString(global.location.search, 'ONLY_AFF');
      this.setState({'affValue': aff});
    }

    render() {
      let { mainSiteUrl } = this.props;
      let { affValue } = this.state;

      return <a href={mainSiteUrl
              ? (affValue
                ? updateQueryStringParameter(
                    mainSiteUrl
                    , AFFILIATE_QUERYSTRING_KEY
                    , affValue)
                : mainSiteUrl)
              : 'javascript:;'}>
            <img src="/static/images/hayhar/fun88-logo.png" className={'qrp-header-logo'} />
          </a>
    }

}