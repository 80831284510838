
const affDomains = {
    
    // "localhost": "309059", //測試用
    // "fun88.biz" : "309059", // 测试用
    // "funvvip86.com" : "309059",
    // "funvvip98.com" : "319749",
    // "fun98vvip.com" : "331906",
    // "fun86vvip.com" : "371284",
    // "vvip66fun.com" :"377115",
    // "fun7706.com"   :"364258",
    // "fun7726.com"   :"364260",
    // "fun7730.com"   :"364259",
    // "fun7736.com"   :"364261"
};
export default affDomains;
