/*
 * @Date: 2023-03-15 18:06:15
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 01:20:54
 * @FilePath: \F1-M1-QRP-CODE\config\trace\traceConfigs.js
 */
/***
 * 這是例子 自行修改
 * 沒有配置的 就填 false
 *
 * const traceConfigs = {
 *   'm2-001': {
 *     piwik: 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
 *     "51la": 21337697,
 *     ga: 'UA-198324647-1',
 *   }
 * }
 * 
 */
import { searchdomain } from "../default/otherDomain";

const searchDomain = () =>{
    if(process.browser){
        let domain =  global.location.host;
        let data = {};
        searchdomain.find(v =>{
            if(domain.match(v.domain)){
                data.domain = v.domain;
                data.la51 = v.la51;
            }
        })
        return data;
    } 
};

const params = searchDomain();


const traceConfigs = {
    'm1-001': {
        piwik: 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la": 21337697,
        ga: false,
        aff : false
    },
    'm1-002' : {
        piwik : 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la" : 21337693 ,
        ga : false,
        aff : false
    },
    'm1-003' : {
        piwik : 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la" : 21337689 ,
        ga : false,
        aff : false
    },
    'm1-004' : {
        piwik : 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la" : 21337685 ,
        ga : false,
        aff : false
    },
    'm1-005' : {
        piwik : 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la" : 21337663 ,
        ga : false,
        aff : false
    },
    'm1-006' : {
        piwik : 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la" : 21337595 ,
        ga : false,
        aff : false
    },
    'm1-007' : {
        piwik : 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la" : params && params.la51 || false ,
        ga : false,
        aff : false
    },
    'm1-008' : {
        piwik : 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la" : false ,
        ga : false,
        aff : false
    },
    'm1-009' : {
        piwik : 'd19c57a4-3c6d-4f8f-aba9-49e90779125e',
        "51la" : 21337697 ,
        ga : false,
        aff : false
    }

}








export default traceConfigs;
