import traceConfigs from "./traceConfigs";
import initPiwik from "./items/piwik";
import init51la from "./items/la51";
import initGA from "./items/ga";
import affDomains from "./affDomains";

const setupTraces = (pageName) => {
  if (process.browser) {
    const thisConfig = traceConfigs[pageName];
    if (thisConfig) {
      if (thisConfig.piwik) {
        initPiwik(thisConfig.piwik);
      }
      if (thisConfig['51la']) {
        init51la(thisConfig['51la']);
      }
      if (thisConfig.ga) {
        initGA(thisConfig.ga);
      }
      if (thisConfig.aff) {
        localStorage.setItem('aff_hardcode', thisConfig.aff);
      }
    }

    const domainName = window.location.hostname;
    if (domainName && domainName.length > 0) {
      const mainDomain = domainName.split('.').slice(-2).join('.').toLowerCase();
      const aff_hardcode = affDomains[mainDomain];
      if (aff_hardcode) {
        localStorage.setItem('aff_hardcode', aff_hardcode);
      // } else {
      //   if (localStorage.getItem('aff_hardcode')) { // clear previous aff_hardcode if have.
      //     localStorage.removeItem('aff_hardcode');
      //   }
      }
    }
  }
}

export default setupTraces;
