/*
 * @Date: 2023-03-17 17:30:18
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 19:58:23
 * @FilePath: \F1-M1-QRP-CODE\config\default\otherDomain.js
 */
// 51LA Code M1-007     


export const searchdomain =  [
    {
        domain : 'fun7706.com',
        la51 : 21026135
    },{
        domain : 'fun7726.com',
        la51 : 21026141
    },{
        domain : 'fun7730.com',
        la51 : 21026147
    },{
        domain : '286226.com',
        la51 : 21026163
    },{
        domain : '277257.com',
        la51 : 21026163
    },{
        domain : 'u836f.com',
        la51 : 21026163
    },{
        domain : 'u968f.com',
        la51 : 21026163
    },{
        domain : 'f3u69.com',
        la51  : 21026163
    },{
        domain : 'u72n9.com',
        la51 : 21026163
    },{
        domain : 'u77n9.com',
        la51 : 21026163
    },{
        domain : 'leyouxi88.com',
        la51 : 21026163
    },{
        domain : 'funball211.com',
        la51 : 21026163
    },{
        domain : 'funfreebet.com',
        la51 : 21026163
    },{
        domain : 'localhost', // 测试
        la51 : 21026147,
        affcode : '12312313'
    },
]
