
export const AFFILIATE_QUERYSTRING_KEY = 'aff'; // main-site & qrp-pages

export const updateQueryStringParameter = function(uri, key, value) { // see https://stackoverflow.com/questions/5999118
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf('?') !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2');
  }
  return uri + separator + key + "=" + value;
}

export const getQueryParams = function(url) { // https://stackabuse.com/get-query-string-values-in-javascript/
  const params = {};
  if (!url || !url.includes('?')) {
    return params;
  }
  const paramArr = url.slice(url.indexOf('?') + 1).split(/&amp;|&/);
  paramArr.map(param => {
      const [key, val] = param.split('=');
      params[key] = decodeURIComponent(val);
  })
  return params;
}
