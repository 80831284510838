/*
 * @Date: 2023-03-17 15:20:59
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-12 12:00:58
 * @FilePath: \F1-M1-QRP-CODE\config\default\banner.js
 */

export const bannerConfigs = {

    'm1-001' : [{
        cmsImageUrlWeb: '/static/images/m1-001/F1M1-desktop.jpg',
        cmsImageUrlMobile: '/static/images/m1-001/F1M1-Mob.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm1-002' : [{
        cmsImageUrlWeb: '/static/images/m1-002/WEB/PC-1.png',
        cmsImageUrlMobile: '/static/images/m1-002/Mob/MB-1.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-002/WEB/PC-2.png',
        cmsImageUrlMobile: '/static/images/m1-002/Mob/MB-2.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-002/WEB/PC-3.png',
        cmsImageUrlMobile: '/static/images/m1-002/Mob/MB-3.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-002/WEB/PC-4.png',
        cmsImageUrlMobile: '/static/images/m1-002/Mob/MB-4.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm1-003' : [{
        cmsImageUrlWeb: '/static/images/m1-003/WEB/PC-1.jpg',
        cmsImageUrlMobile: '/static/images/m1-003/Mob/MB-1.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-003/WEB/PC-2.jpg',
        cmsImageUrlMobile: '/static/images/m1-003/Mob/MB-2.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-003/WEB/PC-3.jpg',
        cmsImageUrlMobile: '/static/images/m1-003/Mob/MB-3.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-003/WEB/PC-4.jpg',
        cmsImageUrlMobile: '/static/images/m1-003/Mob/MB-4.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm1-004' : [{
        cmsImageUrlWeb: '/static/images/m1-004/WEB/PC-1.png',
        cmsImageUrlMobile: '/static/images/m1-004/Mob/MB-1.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-004/WEB/PC-2.png',
        cmsImageUrlMobile: '/static/images/m1-004/Mob/MB-2.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-004/WEB/PC-3.png',
        cmsImageUrlMobile: '/static/images/m1-004/Mob/MB-3.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-004/WEB/PC-4.png',
        cmsImageUrlMobile: '/static/images/m1-004/Mob/MB-4.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm1-005' : [{
        cmsImageUrlWeb: '/static/images/m1-005/WEB/PC-1.png',
        cmsImageUrlMobile: '/static/images/m1-005/Mob/MB-1.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-005/WEB/PC-2.png',
        cmsImageUrlMobile: '/static/images/m1-005/Mob/MB-2.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-005/WEB/PC-3.png',
        cmsImageUrlMobile: '/static/images/m1-005/Mob/MB-3.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    },{
        cmsImageUrlWeb: '/static/images/m1-005/WEB/PC-4.png',
        cmsImageUrlMobile: '/static/images/m1-005/Mob/MB-4.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    
    'm1-006' : [{
        cmsImageUrlWeb: '/static/images/m1-006/WEB/PC-1.jpg',
        cmsImageUrlMobile: '/static/images/m1-006/Mob/MB-1.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    
    'm1-007' : [{
        cmsImageUrlWeb: '/static/images/m1-007/WEB/PC-1.jpg',
        cmsImageUrlMobile: '/static/images/m1-007/Mob/MB-1.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm1-008' : [{
        cmsImageUrlWeb: '/static/images/m1-007/WEB/PC-1.jpg',
        cmsImageUrlMobile: '/static/images/m1-007/Mob/MB-1.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm1-009' : [{
        cmsImageUrlWeb: '/static/images/m1-009/WEB/PC-1.jpg',
        cmsImageUrlMobile: '/static/images/m1-009/Mob/MB-1.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],


};